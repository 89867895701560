import * as React from "react"
import { CartContext } from "../../core/cart.context"
import {calls, PROJECT_COUNTRY, PROJECT_LANGUAGE} from "../../core/APIUtils"
import { RESTContext } from "../../core/rest.context"
import { Button } from "../button/button.component"
import { StyledExternalPromoPopup } from "./externalPromoPopup.style"
import { LabelContext } from "../../core/label.context"
import { StyledInput } from "../input/input.style"

export const ExternalPromoPopup = (props: any) => {
    const [productPromo, setProductPromo] = React.useState<any>([])
    const [qtaProduct, setQtaProduct] = React.useState<number>(0)
    const [qtaPromo, setQtaPromo] = React.useState<number>(0)
    const [delta, setDelta] = React.useState<number>(0)
    const [checked, setChecked] = React.useState<string>('')
    const [prevChecked, setPrevChecked] = React.useState<string>('')
    const [ajaxEnd, setAjaxEnd] = React.useState<boolean>(false)

    const [popupTitle, setPopupTitle] = React.useState<string>('')
    const [popupDescription, setPopupDescription] = React.useState<string>('')

    const { t } = React.useContext(LabelContext)
    const { ajax$ } = React.useContext(RESTContext)
    const { updateExternalPromoToCart, externalPopup } = React.useContext(CartContext);

    React.useEffect(() => {
        externalPopup ? document.body.classList.add("modal-open") : document.body.classList.remove("modal-open");
        let categoryId = ''
        props.cart.lineItems.forEach((item: any) => {
            const discounts = item && item["discountedPrice"] && item["discountedPrice"]["includedDiscounts"] && item["discountedPrice"]["includedDiscounts"].length > 0
            if (discounts) {
                return item["discountedPrice"]["includedDiscounts"].forEach((_item: any) => {
                    if (_item['discount'] &&
                        _item['discount']['obj'] &&
                        _item['discount']['obj']['custom'] &&
                        _item['discount']['obj']['custom']['fields'] &&
                        _item['discount']['obj']['custom']['fields']['category-of-gift'] &&
                        _item['discount']['id'] === props.promoId
                    ) {
                        categoryId = _item['discount']['obj']['custom']['fields']['category-of-gift']['id']
                    }
                })
            }
        })
        const promoArray: { key: any; qta: number; img: string, lineitemId: string, name: string, descr: string, promoId: string }[] = []
        ajax$(calls.Project.getProductProjection("categories.id:\"" + categoryId + "\"")).subscribe((res: any) => {
            let qtaPromo = 0
            res["results"].forEach((product: any) => {
                if (product.masterVariant) {
                    let img = ""
                    if (product.masterVariant['images'] && product.masterVariant['images'].length > 0)
                        img = product.masterVariant['images'][0]['url']
                    let productKey = product.masterVariant['key']
                    let name = product.name[PROJECT_LANGUAGE]
                    let descr = product.description ? product.description[PROJECT_LANGUAGE] : ''
                    const qta = props.cart.lineItems.find((item: any) => item && item['productKey'] === productKey)?.quantity || 0
                    const lineitemId = props.cart.lineItems.find((item: any) => item && item['productKey'] === productKey)?.id || ""
                    promoArray.push({ key: productKey, qta: qta, img: img, lineitemId: lineitemId, name: name, descr: descr, promoId: props.promoId })
                    qtaPromo += parseInt(qta)
                }
            })


            //conta i prodotti aggiunti a carrello
            let qtaProduct = 0, qtaProductGroup1 = 0, qtaProductGroup2 = 0;
            let _popupTitle = ''
            let _popupDesc = ''
            props.cart.lineItems.forEach((item: any) => {
                const discounts = item && item["discountedPrice"] && item["discountedPrice"]["includedDiscounts"] && item["discountedPrice"]["includedDiscounts"].length > 0
                if (discounts && props.promoId) {
                    item["discountedPrice"]["includedDiscounts"].forEach((_item: any) => {
                        if (_item["discount"]["id"] === props.promoId) {
                            if(item["productKey"].indexOf("ECF01") === 0){
                                qtaProductGroup1 += item.quantity
                            }else if(item["productKey"].indexOf("CGF01") === 0){
                                qtaProductGroup2 += item.quantity
                            }
                            qtaProduct += item.quantity
                            if (_item['discount']['obj'] && _item['discount']['obj']['name']) _popupTitle = _item['discount']['obj']['name'][PROJECT_LANGUAGE]
                            if (_item['discount']['obj'] && _item['discount']['obj']['description']) _popupDesc = _item['discount']['obj']['description'][PROJECT_LANGUAGE]
                        }
                    })
                }
            })

            //casistica per Italia: quando la promozione si attiva con due prodotti a carrello
            if (PROJECT_COUNTRY === "IT"){
                qtaProduct = qtaProductGroup1 < qtaProductGroup2 ? qtaProductGroup1 : qtaProductGroup2;
            }

            setPopupTitle(_popupTitle)
            setPopupDescription(_popupDesc)
            setDelta(qtaProduct - qtaPromo)
            setQtaProduct(qtaProduct)
            setQtaPromo(qtaPromo)
            if (qtaProduct !== qtaPromo) document.body.classList.add("modal-open")
            setProductPromo(promoArray)
            setAjaxEnd(true)
        })

    }, [])

    //Aggiunto per gestire il popup nel caso in cui ci sia solo un prodotto in promozione
    React.useEffect(() => {
        if (ajaxEnd && productPromo.length === 1){
            if(delta !== 1){
                handlePromoChangeQta(qtaProduct.toString(), productPromo[0].key)
            }else{
                handlePromoChangeQta(productPromo[0].qta + 1, productPromo[0].key)
            }
        }
    }, [ajaxEnd])

    const canPopupProceed = () => {
        return qtaProduct === qtaPromo;
    }


    const handlePromoChangeQta = (qta: string, key: any) => {
        let _productPromo = Object.assign([], productPromo)
        let qtaPromo = 0
        for (let i = 0; i < Object.keys(_productPromo).length; i++) {
            //gestione incremento radio button
            if ((delta) === 1 && prevChecked !== '' && _productPromo[i].key === prevChecked && _productPromo[i].qta > 0) {
                _productPromo[i].qta--
            }
            //caso: macchina1 - qta3 | caffe1 - qta3 | macchina2 - qta4 | caffe2 - qta4 -> per il caffe2
            if (_productPromo[i].qta > qtaProduct) {
                _productPromo[i].qta = 0
            }
            //aggiornamento qta
            if (_productPromo[i].key === key) {
                _productPromo[i].qta = parseInt(qta)
            }
            qtaPromo += _productPromo[i].qta
        }
        setProductPromo(_productPromo)
        setQtaPromo(qtaPromo)
        setPrevChecked(key)
    }

    const qtaNotFilledLabel = delta === 1 ? t("ecommerce.common.popup-external-promo.insertOneElement") : t("ecommerce.common.popup-external-promo.qtanotfilled").replace('[qta]', qtaProduct.toString())
    const qtaAlert = t("ecommerce.common.popup-external-promo.qtaAlert").replace('[qta]', (delta === 1) ? "1" : qtaProduct.toString())

    return (
        <>
            <StyledExternalPromoPopup>
                <div
                    id="externalPopup"
                    className="modal show fade eforms-modal white"
                    style={{ display: "block" }}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header" />
                            <div className="modal-body">
                                <div className="container-fluid ">
                                    <div className="header text-center">
                                        <h3 className="title title-lg pb-3">{popupTitle}</h3>
                                        <div className="text">{popupDescription}</div>
                                        <div className="text-bold">{qtaAlert}</div>
                                    </div>
                                    <div className={`row modal-scroll-content mb-3 ${productPromo.length === 1 ? ' justify-content-center text-center': ''}`}>
                                        {
                                            productPromo.map((product: any) => {
                                                return (
                                                    <div className={`d-flex justify-content-between flex-column col-12 ${productPromo.length > 4 || productPromo.length === 3 ? 'col-md-4': 'col-md-6'} mt-4`}>
                                                        <div className="productItem text-center">
                                                            <img
                                                                src={product.img}
                                                                className="productImage img-fluid"
                                                                alt={product.key}
                                                            />
                                                            <div className="textBlock text-left">
                                                                <div className="title"><b>{product.name}</b></div>
                                                                <div className="text">{product.descr}</div>
                                                            </div>
                                                        </div>
                                                        {
                                                            (delta) === 1 && (
                                                                <>
                                                                    <div className="radioGroup__generic text-center mt-3">
                                                                        <div className={`radioButton ${productPromo.length === 1 ? ' hidden': ''}`}>
                                                                            <input
                                                                                id={product.key.toLowerCase()}
                                                                                value={product.key.toLowerCase()}
                                                                                name={product.key.toLowerCase()}
                                                                                type="radio"
                                                                                checked={productPromo.length === 1 ? true : product.key.toLowerCase() === checked}
                                                                                onChange={(e) => { setChecked(e.target.value.toLowerCase()); handlePromoChangeQta(product.qta + 1, product.key) }}
                                                                            />
                                                                            <label htmlFor={product.key.toLowerCase()} />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            (delta) !== 1 && (
                                                                <>
                                                                    <div className='justify-content-center row mt-3'>
                                                                        <div className={`col-4 ${productPromo.length === 1 ? ' hidden': ''}`}>
                                                                            <StyledInput>
                                                                                <select onChange={(e) => handlePromoChangeQta(e.target.value, product.key)} value={productPromo.length === 1 ? qtaProduct : ((qtaProduct < product.qta) ? 0 : product.qta )}>
                                                                                    {[...Array(qtaProduct + 1)].map((e, i) => {
                                                                                        return (
                                                                                            <option key={i} value={i} >
                                                                                                {i}
                                                                                            </option>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            </StyledInput>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="row text-center">
                                        <div className="col">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: !canPopupProceed() ? qtaNotFilledLabel : '&nbsp;',
                                                }}
                                                className="w-100 error message--alert small"
                                            />
                                            <Button
                                                className="primary mb-0 color--alert"
                                                btn_disabled={!canPopupProceed()}
                                                onClick={() => {
                                                    document.body.classList.remove("modal-open")
                                                    updateExternalPromoToCart(productPromo)
                                                }}
                                                label={t("ecommerce.common.product.addToCart")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledExternalPromoPopup>
        </>
    )
}
