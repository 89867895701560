import { createPrice, parsePrice } from "../../common/constants";
import {PROJECT_CURRENCY, PROJECT_LANGUAGE, PROJECT_SETTINGS} from "../../core/APIUtils"


export const isBundleAvailable = (bundleProjection: any) => {
    let skuList = getAttributeFromProductProjection(bundleProjection, "bundle")
    let bundleAvailable = true
    let minQta: number | undefined = undefined

    skuList.forEach((item: any) => {
        const availableQuantity = item.obj?.masterData?.current?.masterVariant?.availability?.availableQuantity

        // Controlla la disponibilità del prodotto e aggiorna bundleAvailable se necessario
        if (!item.obj || !item.obj?.masterData.current.masterVariant.availability || (availableQuantity < 1 && PROJECT_SETTINGS.inventoryMode !== "TrackOnly")) {
            bundleAvailable = false
        }

        // Calcola il minQta solo se availableQuantity è definito e valido
        if (availableQuantity !== undefined) {
            if (minQta === undefined) {
                minQta = availableQuantity
            } else if (availableQuantity < minQta) {
                minQta = availableQuantity
            }
        }
    })

    return { bundleAvailable, minQta }
}


export const customDiscountLabel = (attributes: any) => {
    if(!attributes)return null
    let customDiscount = attributes.find((attr: any) => attr.name === 'custom-discount-label' && attr.value && attr.value[PROJECT_LANGUAGE])
    let customDiscountLabel = null
    if(customDiscount){
        customDiscountLabel = customDiscount.value[PROJECT_LANGUAGE]
    }
    return customDiscountLabel
}

export const isBundleInCart = (bundleProjection:any, cart:any) => {
    let found: boolean = false

    let skuInsideCart: string[] = []
	cart.lineItems.map((lineitem: any) => {
	    skuInsideCart.push(lineitem.variant.sku)
	})

    let skuInsideBundle = getAttributeFromProductProjection(bundleProjection, "bundle")

    let numberOfProductOfBundleInCart = 0
    skuInsideBundle.forEach((_el: any) => {
        if (skuInsideCart.indexOf(_el.obj.key) !== -1 && !found) {
            numberOfProductOfBundleInCart++
        }
    })    
    return (numberOfProductOfBundleInCart === skuInsideBundle.length)
}

export const isBundleCompatibleWithCart = (bundleProjection:any, cart:any) => {
    let found: boolean = false

    let skuInsideCart: string[] = []
	cart.lineItems.map((lineitem: any) => {
	    skuInsideCart.push(lineitem.variant.sku)
	})

    let skuInsideBundle = getAttributeFromProductProjection(bundleProjection, "bundle")

    let numberOfProductOfBundleInCart = 0
    skuInsideBundle.forEach((_el: any) => {
        if (skuInsideCart.indexOf(_el.obj.key) !== -1 && !found) {
            numberOfProductOfBundleInCart++
        }
    })    
    return (numberOfProductOfBundleInCart>0)
}

//rimuovo i bundle che hanno dei prodotti che sono stati cancellati perche non presenti piu su elastic (non esiste il campo "obj") oppure sono non pubblicati
export const removeBundleCancelledFromProductProjection = (bundleProjection: any) => {
    return bundleProjection.filter((product: any) => {
        const bundleAttributes = product.masterVariant.attributes.find((attr: any) => attr.name === "bundle")

        if (!bundleAttributes) {
            return false
        }

        return bundleAttributes.value.every((attrValue: any) => attrValue.obj && attrValue.obj.masterData.published)
    })
}

export const getAttributeFromProductProjection = (productProjection:any, attributeName:string) =>{
	let attributes = productProjection.masterVariant?.attributes.filter( 
	    (_a:any) => { return _a.name === attributeName }
	).map( (__a:any) => {return __a.value}  )

	let result = null;
	if(attributes.length>0){
		result = attributes[0]
	}
	return result

}

export const getBundlePrice = (bundleProjection:any) => {
    let skuList = getAttributeFromProductProjection(bundleProjection, "bundle")
    let res = isBundleAvailable(bundleProjection)
    let minQta = res.minQta
    let isAvailable = res.bundleAvailable

    // Prezzo impostato sul bundle
    let bundlePrice = getCurrentPrice(bundleProjection.masterVariant.prices)?.value.centAmount

    // Prezzo intero dei prodotti del bundle
    let productsPrice: number = 0
    skuList.forEach((item: any) => {
        let _price = getCurrentPrice(item.obj?.masterData.current.masterVariant.prices)?.value.centAmount
        productsPrice = productsPrice + _price
    });

    let bundlePriceFormatted = bundlePrice? parsePrice(createPrice(bundlePrice, PROJECT_CURRENCY)) : null

    let productsPriceFormatted = parsePrice(createPrice(productsPrice, PROJECT_CURRENCY))

    let discountPercentValue;
    if(productsPrice>0 && bundlePrice){
        discountPercentValue = Math.round(100 * (productsPrice - bundlePrice) / productsPrice)
    } else {
        discountPercentValue = 0
    }

    let result: any = {
        bundlePrice: bundlePrice, 
        productsPrice: productsPrice, 
        savingPrice: productsPrice-bundlePrice, 
        bundlePriceFormatted: bundlePriceFormatted, 
        productsPriceFormatted: productsPriceFormatted, 
        savingPriceFormatted: parsePrice(createPrice(productsPrice-bundlePrice,PROJECT_CURRENCY)), 
        discountPercentValue: discountPercentValue,
        isAvailable: isAvailable,
        minQta: minQta
    }
    
    return result
}

export const getCurrentPrice = (prices: any []) => {
    if (prices.length > 0){
        let price = prices.find((price: any) => {
            let now = new Date()
            if (price.validFrom && price.validUntil){
                let from = new Date(price.validFrom)
                let until = new Date(price.validUntil)
                if (from <= now && now <= until) {
                    return price
                }
            }
        })

        if (price === undefined)
            return prices[0]
        else
            return price
    }else{
        return undefined
    }

}

export const getEnergyClassLabel = (masterVariant: any, t: (labelValue: string) => string) => {
    const energyLabelValue: string = masterVariant?.attributes.find((attr: any) => attr.name === "ENERGY_CLASS_ICON")?.value?.key

    if(energyLabelValue){
        const label = t(energyLabelValue.replaceAll("Plus", "+"))
        return {
            src: `https://smegpix.4flow.cloud/smegpixpath/Symbols/${energyLabelValue.replaceAll("Plus", "+")}.jpg`,
            alt: label
        }
    }
    return undefined
}

