import * as React from "react";
import ReactDOM from "react-dom";
import { GeneralContext } from "../../core/general.context";
import { Box } from "../box/box.component";
import { Button } from "../button/button.component";
import { InputElement } from "../input/input.component";
import {StyledLogin, StyledPopupLogin} from "./login.style";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { LabelContext } from "../../core/label.context";
import {BRX_BASE_URL, IS_GIFT_CARD_ACTIVE} from "../../core/APIUtils";
import { UserContext } from "../../core/user.context";
import { getBrowserInfo, inputMaxLength, nordics } from "../../common/constants";
import {PROJECT_KEY} from "../../core/APIUtils"


type LoginValue = {
  email: string,
  password: string,
}


export const Login = (props: any) => {
  const { className, show, isSideBarLogin } = props;
  const { login } = React.useContext(UserContext);
  const { t } = React.useContext(LabelContext);
  const sidebarLogin = isSideBarLogin ? "SidebarLogin" : "";

  let requiredError = t("ecommerce.form-messagge.required")
  let emailError = t("ecommerce.form-messagge.invalid-email")


  const schema = yup.object().shape({
    email: yup.string().email(emailError).required(requiredError),
    password: yup.string().max(inputMaxLength).required(requiredError)
  });

  const { register, handleSubmit, formState: { errors } } = useForm<LoginValue>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<LoginValue> = data => {
    login(data.email, data.password);
    document.body.style.overflowY = "scroll";
    const asideCart = document?.querySelector(".checkout-column-aside")
    const preFooter = document?.querySelector(".pre-footer");
    if(asideCart && asideCart instanceof HTMLElement){
      asideCart.style.zIndex = "0"
      // asideCart.style.position = "sticky"
    }
    if(preFooter && preFooter instanceof HTMLElement){
      // preFooter.style.position = "relative"
      preFooter.style.zIndex = "0"
    }
  }

  // controllo al onChange se la password è settata 
  const fieldPassword = register("password", { required: true })
  const origOnChange = fieldPassword.onChange
  fieldPassword.onChange = (e) => {
    const res = origOnChange(e)
    return res
  }

  return (

    <StyledLogin className={`${className}`}>
      <Box className="boxed-mobile secondary unbordered">
        <div className="centerBlocks">
          <div>
            <h4 className="text-center">{t("ecommerce.login.doLogin")}</h4>

            <form onSubmit={handleSubmit(onSubmit)} >
              <InputElement label={t("ecommerce.signup.email")} placeholder={t("ecommerce.login.email")} id={`email${sidebarLogin}`} externalRegister={register("email")} errors={errors.email?.message} ></InputElement>
              <InputElement className="text-left" type="password" label={t("ecommerce.login.password")} placeholder={t("ecommerce.login.password")} id={`password${sidebarLogin}`} externalRegister={fieldPassword} errors={errors.password?.message} ></InputElement>
              <Button className="primary mt-4" label={t("ecommerce.login.signIn")} type="submit" value={t("ecommerce.login.signIn")} />
            </form>
            <div className="passwordRecovery text-center">
              <a href={BRX_BASE_URL + '/forgot-password'}>{t("ecommerce.login.forgotPassword")}</a>
            </div>
            {show?.includes('register') && (<Button redirectPath={BRX_BASE_URL + '/register'} className="primary color--alert" label={t("ecommerce.login.signUp")} ></Button>)}
          </div>
        </div>
      </Box>
    </StyledLogin>
  );
};

export const LoginPopup = () => {
  const {t} = React.useContext(LabelContext)
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  const handleLoginClick = () => {
    setIsPopupOpen(true);
    document.body.style.overflow = "hidden";
    const asideCart = document?.querySelector(".checkout-column-aside")
    const preFooter = document?.querySelector(".pre-footer");
    if(asideCart && asideCart instanceof HTMLElement){
      asideCart.style.zIndex = "-1"
      // asideCart.style.position = "relative"
    }
    if(preFooter && preFooter instanceof HTMLElement){
      // preFooter.style.position = "relative"
      preFooter.style.zIndex = "-1"
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    document.body.style.overflow = "scroll";
    const asideCart = document?.querySelector(".checkout-column-aside")
    const preFooter = document?.querySelector(".pre-footer");
    if(asideCart && asideCart instanceof HTMLElement){
      asideCart.style.zIndex = "0"
      // asideCart.style.position = "sticky"
    }
    if(preFooter && preFooter instanceof HTMLElement){
      // preFooter.style.position = "relative"
      preFooter.style.zIndex = "0"
    }
  };

  return (
      <div>
        <div className="login-wrapper pb-3">
          <span>{t("ecommerce.login.popup-title")}</span>
          <button
              className={`btn-checkout ml-3`}
              onClick={handleLoginClick}
          >
            {t("ecommerce.login.signIn")}
          </button>
        </div>
        {isPopupOpen && (
            <StyledPopupLogin>
              <div className={`popup ${nordics.includes(PROJECT_KEY) && 'popup-nordics'}`}>
                <div className="popup-content">
                  <button className="close-popup" onClick={handleClosePopup}>×</button>
                  <Login />
                </div>
              </div>
            </StyledPopupLogin>
        )}

      </div>
  );
};

export const EasyLogin = () => {

  const { userData } = React.useContext(GeneralContext);
  const { logout } = React.useContext(UserContext);
  const container_btn = document.getElementsByClassName("reactPlaceholder_accountLayer_btn");
  const container = document.getElementsByClassName("reactPlaceholder_accountLayer");
  const { t } = React.useContext(LabelContext);

  const loginButton = <button className="ec_btn ec_btn-accountLayer">
    <span className="icon"/>
    <span className="testo">My Account</span>
  </button>;

  const accountPageLink = BRX_BASE_URL + "/my-account";


  let myAccount_welcome = (userData && userData.salutation === "female") ? t("ecommerce.login.welcome-female") : t("ecommerce.login.welcome-male");
  if (userData && userData.salutation === "other") {
    myAccount_welcome = t("ecommerce.login.welcome-other")
  }
  // const myAccount_welcome = (userData.salutation == "female") ? "benvenuta" : "benvenuto";

  const userLoggedLayer = userData &&
    <div className="userLoggedLayer header_layer-container">
      <div className="header_layer-title"> {myAccount_welcome}, <span>{userData.firstName}</span>
        <div>{userData.isEmailVerified ? t("ecommerce.myaccount.email-verified-short-message") : t("ecommerce.myaccount.email-not-verified-short-message")} </div>
      </div>
      <div className="header_layer-body">
        <ul className="userLoggedMenu">
          <li><a href={`${accountPageLink}#personalInfomations`}>{t("ecommerce.myaccount.personal-info-title")} </a></li>
          <li><a href={`${accountPageLink}#my-accessdata`}>{t("ecommerce.myaccount.access-data-title")}</a></li>
          <li><a href={`${accountPageLink}#my-orders`}>{t("ecommerce.myaccount.orders-title")}</a></li>
          <li><a href={`${accountPageLink}#my-wishlist`}>{t("ecommerce.myaccount.wishlist-title")}</a></li>
          <li><a href={`${accountPageLink}#my-returns`}>{t("ecommerce.myaccount.return-title")}</a></li>
          {IS_GIFT_CARD_ACTIVE && <li><a href={`${accountPageLink}#my-giftcard`}>{t("ecommerce.myaccount.giftcard-title")}</a></li>}
        </ul>
        <br /> <br />
        <br /> <br />
        <button className="ec_btn ec_btn_logoutLayer" onClick={logout} >{t("ecommerce.login.logout")}</button>
      </div></div>;

  if (!container)
    return null;

  else {
    return <>

      {Array.from(container_btn).map((button, i) =>
        ReactDOM.createPortal(
          loginButton,
          button
        )
      )
      }
      {Array.from(container).map((layer, i) =>
        ReactDOM.createPortal(
          (userData ? userLoggedLayer : <Login className="easy-login" show="register" isSideBarLogin={true} />),
          layer
        )
      )
      }


    </>
  }

};
