import * as React from "react";
import {GeneralContext} from "../../core/general.context";
import {Box} from "../box/box.component";
import {LabelContext} from "../../core/label.context";
import {
    PROJECT_CURRENCY
} from "../../core/APIUtils";
import {createPrice, parseDate, parsePrice} from "../../common/constants"
import {CartContext} from "../../core/cart.context"
import {InputElement} from "../input/input.component"
import {Button} from "../button/button.component"
import {UserContext} from "../../core/user.context"
import * as yup from "yup"
import {SubmitHandler, useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import {IGiftCardMovement} from "../../common/common.models"

type IGiftCardValues = {
    serial: string;
    cvc: string;
};

export const GiftCard = () => {
    const {giftCards, giftCardsMovements } = React.useContext(GeneralContext);
    const {t} = React.useContext(LabelContext);
    const { giftcardActivated, activateGiftcard } = React.useContext(UserContext);
    const {loadGiftCards, loadingGiftCardsMovements} = React.useContext(CartContext);

    React.useEffect(() => {
        loadGiftCards(false)
    }, [giftcardActivated]);

    let requiredError = t("ecommerce.form-messagge.required");
    let inputMaxLengthError = t("ecommerce.form-messagge.error-input-max-length-cvc")
    let inputMaxLengthSerial = 10
    let inputMaxLengthCvc = 3

    const schema = yup.object().shape({
        serial: yup.string().max(inputMaxLengthSerial, inputMaxLengthError).required(requiredError),
        cvc: yup.string().max(inputMaxLengthCvc, inputMaxLengthError).required(requiredError),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IGiftCardValues>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<IGiftCardValues> = (data) => {
        activateGiftcard(data.serial, data.cvc)
    };


    return (
        <>
            <Box className="unboxed secondary unbordered">
                <div className="row justify-content-md-center">
                    <div className="col-md-6">
                        <Box className="secondary">
                            <h3>{t("ecommerce.myaccount.giftCards-activation-title")}</h3>

                            {giftcardActivated === "NO" && (
                                <div className="centerBlocks">
                                    <div className="text-center">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <InputElement
                                                label={t("ecommerce.giftcard.serial")}
                                                id="serial"
                                                externalRegister={register("serial")}
                                                errors={errors.serial?.message}
                                            />
                                            <InputElement
                                                label={t("ecommerce.giftcard.cvc")}
                                                id="cvc"
                                                externalRegister={register("cvc")}
                                                errors={errors.cvc?.message}
                                            />
                                            <Button
                                                className="primary mt-4"
                                                label={t("ecommerce.giftcard.activate")}
                                                type="submit"
                                            />
                                        </form>
                                    </div>
                                </div>
                            )}
                            {giftcardActivated === "YES" && (
                                <>
                                    <div className="text-center">
                                        <p>
                                            {t("ecommerce.giftcard.activated-message")}
                                        </p>
                                    </div>
                                </>
                            )}
                            {giftcardActivated === "ERROR" && (
                                <>
                                    <div className="text-center message--alert small">
                                        <p>
                                            {t("ecommerce.giftcard.error-message")}
                                        </p>
                                    </div>
                                </>
                            )}
                        </Box>
                    </div>
                </div>
            </Box>

            <Box className="unboxed secondary unbordered">
                <div className="row">
                    <div className="col-md-12">
                        <Box className="secondary">
                            <h3>{t("ecommerce.myaccount.giftCards-title")}</h3>

                            {giftCards?.length === 0 ?
                                <>
                                    <Box className="secondary unbordered">
                                        <p className="text-center">{t("ecommerce.myaccount.giftCards-empty")}</p>
                                    </Box>
                                </>
                                : ////// else
                                <>
                                    {giftCards?.map((giftCard: any, index) =>
                                        <React.Fragment key={index}>
                                            <div className={`justify-content-center py-1 ${index !== 0 ? "mt-3" : ""}`}>
                                                <div className="col">
                                                    <div className="row">
                                                        <b>{giftCard.giftCardName}
                                                            {giftCard.isExpired &&
                                                                <span style={{color: 'var(--color-alert)'}}>  ({t("ecommerce.myaccount.giftCards-expired")})</span>
                                                            }
                                                        </b>
                                                    </div>
                                                    <div className="row">
                                                        <span>{t("ecommerce.myaccount.giftCards-valueAmount")} : {parsePrice(createPrice(giftCard.value, PROJECT_CURRENCY))}</span>
                                                    </div>
                                                    <div className="row">
                                                        <span>{t("ecommerce.myaccount.giftCards-residualAmount")} : {parsePrice(createPrice(giftCard.residualValue, PROJECT_CURRENCY))}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {!loadingGiftCardsMovements &&
                                            <>
                                                {Object.entries(giftCardsMovements as Record<string, IGiftCardMovement[]>).map(([key, movements], movementIndex) =>
                                                    Number(key) === giftCard.id && (
                                                        <React.Fragment key={movementIndex}>
                                                            {movements.map((movement: IGiftCardMovement, innerIndex) => {
                                                                if (!movement.isDeleted) {
                                                                    let orderNumber = movement.orderNumber || "";
                                                                    let amount = parsePrice(createPrice(Math.abs(movement.value), PROJECT_CURRENCY)) || ""
                                                                    let date = parseDate(movement.createdDate) || ""

                                                                    const isCredit = movement.value < 0;
                                                                    const sign = isCredit ? '+' : '-';
                                                                    const labelKey = isCredit ? "ecommerce.myaccount.giftCards-movementLabel" : "ecommerce.myaccount.giftCards-refundLabel";

                                                                    const label = t(labelKey)
                                                                        .replace("[AMOUNT]", `${sign}${amount}`)
                                                                        .replace("[DATE]", date)
                                                                        .replace("[ORDER]", orderNumber);

                                                                    return <div key={innerIndex}>{label}</div>;
                                                                }
                                                            })}
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </>
                                            }

                                        </React.Fragment>
                                    )}
                                </>
                            }
                        </Box>
                    </div>
                </div>
            </Box>
        </>
    );
};


