import * as React from "react"
import {LabelContext} from "../../core/label.context"
import {CartContext} from "../../core/cart.context"
import {GeneralContext} from "../../core/general.context"
import {getCurrentPrice, getEnergyClassLabel} from "../bundle/utils"
import {
    BRX_PRODUCTS_URL,
    PROJECT_COUNTRY,
    PROJECT_LANGUAGE,
    SHOW_ENERGY_LABEL
} from "../../core/APIUtils"
import {openCartLayer, parsePrice} from "../../common/constants"
import { StyledFrequentlyBought, StyledFrequentlyBoughtList } from "./frequentlyBought.style";

export const FrequentlyBoughtComponent = (props: any) => {
    const { relatedItems, relatedAccessories, title } = props;
    const { t } = React.useContext(LabelContext);
    const [productsToShow, setProductsToShow] = React.useState<any>([])
    const [itemCount, setItemCount] = React.useState(6);

    const showMoreItems = () => {
        setItemCount(itemCount + 6);
    };

    const showLessItems = () => {
        setItemCount(6);
    };

    React.useEffect(() => {

        //get only product with inventory > 0
        const relProduct = relatedItems.filter((item: any) =>
            item.obj?.masterData?.current?.masterVariant?.availability?.availableQuantity > 0
        )

        //get only accessories with inventory > 0
        const relAccessories = relatedAccessories.filter((item: any) =>
            item.obj && item.obj?.masterData?.current?.masterVariant?.availability?.availableQuantity > 0
        )

        //merge product and accessories
        const mergedArray = [...relProduct, ...relAccessories]

        //remove duplicates
        const uniqueProducts = mergedArray.reduce((unique: any[], product: any) => {
            if (product.obj && product.obj.key) {
                const existingProduct = unique.find(p => p.obj && p.obj.key === product.obj.key);
                if (!existingProduct) {
                    unique.push(product);
                }
            }
            return unique;
        }, []);


        setProductsToShow(uniqueProducts)
    },[])


    return (<>
        {productsToShow.length > 0 &&
        <StyledFrequentlyBoughtList>
            <section className="ecommerce_relatedItems white-section">
                <h2 className="title text-black">{title ? title : t("ecommerce.common.related.title")}</h2>
                <div className="products-list-small">
                        {
                            productsToShow.slice(0, itemCount).map((item: any, i: any) => {
                                if (item.obj && item.obj?.masterData?.current?.masterVariant?.availability?.availableQuantity > 0) {
                                    return (<FrequentlyBought item={item} i={i} />)
                                }
                            })
                        }
                        {itemCount < productsToShow.length && (
                            <button onClick={showMoreItems } className="btn-viewMore">
                                <span className="icon-more">+</span><span> {t("ecommerce.common.viewMore")}</span>
                            </button>
                        ) }
                        {itemCount >= productsToShow.length && itemCount > 6 && (
                            <button onClick={showLessItems} className="btn-viewMore">
                                <span className="icon-more">-</span><span>{t("ecommerce.common.viewLess")}</span>
                            </button>
                        )}
                </div>
            </section>
            </StyledFrequentlyBoughtList>
        }
    </>)
}

export const FrequentlyBought = (props: any) => {
    const { item, i } = props;
    const { t } = React.useContext(LabelContext);
    const { addLineItem } = React.useContext(CartContext);
    const { wishlist } = React.useContext(GeneralContext);

    let currentItem: any
    let name: string
    let available: any

    currentItem = item.obj?.masterData?.current?.masterVariant
    name = item.obj?.masterData?.current?.name;
    available = currentItem.availability?.availableQuantity

    const energyLabel = getEnergyClassLabel(currentItem, t)
    const productSheetUrl = currentItem.attributes.find((attr: any) => attr.name === 'product-sheet-url' && attr.value)

    const { sku, images, prices } = currentItem;
    const { discounted } = getCurrentPrice(prices);

    let inWishlist: any = false
    if (wishlist && wishlist.lineItems.length > 0) {
        inWishlist = wishlist.lineItems.find((item) => (item.variant && item.variant.sku === sku));
    }

    return (
        <StyledFrequentlyBought key={i} className="small-listItem">
            <div className="product-content">
                <a href={BRX_PRODUCTS_URL + sku}>
                    <div className="product-preview__gallery">
                        <img src={images[0].url} alt="" />
                    </div>
                    <div className="product-preview__header">
                        <div className="name" style={{"fontWeight":"bold", "lineHeight": "24px"}} >{name[PROJECT_LANGUAGE]}</div>
                        
                        <div className="promotion-preview">
                            <div className="promotion-preview__header">
                            <div className="name-sku">{sku}</div>
                                <div className="prices">
                                    {getCurrentPrice(prices).value.centAmount > 0 && (
                                        discounted ? (
                                            <>
                                                <div className="price price--strikethrough">{parsePrice(getCurrentPrice(prices).value)}</div>
                                                <div className="price price--discounted">{parsePrice(discounted.value)}</div>
                                            </>
                                        ) : (
                                            <div className="price">{parsePrice(getCurrentPrice(prices).value)}</div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="d-inline-flex mx-0 energy_label_text">
                            {SHOW_ENERGY_LABEL && (energyLabel || (productSheetUrl && productSheetUrl !== "")) &&
                                <>
                                    {energyLabel &&
                                        <a target="_blank" rel="noreferrer" href={`https://pi-exchange.smeg.it/energy/${sku}/${PROJECT_LANGUAGE}-${PROJECT_COUNTRY}`}>
                                            <img src={energyLabel.src} alt={energyLabel.alt} />
                                        </a>
                                    }
                                    {productSheetUrl && productSheetUrl !== "" &&
                                        <a href={productSheetUrl.value?.replaceAll(" en-GB", " en-IE")} target="_blank" rel="noreferrer" className={`font-weight-light ${SHOW_ENERGY_LABEL ? 'pl-3' : ''}`}>{t("product.productSheetLabel")}</a>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </a>
            </div>

            <div className="product-links">
                <div className="product-link__buy">
                    {available &&
                        <button data-id={sku} onClick={() => { addLineItem(sku); openCartLayer(); }} className="btn_addToCart button-primary" title={t("ecommerce.common.product.addToCart")}>
                            <span className="btn-text">{t("ecommerce.common.product.addToCartShort")}</span>
                            <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                                <path d="M9 22.5C9.55228 22.5 10 22.0523 10 21.5C10 20.9477 9.55228 20.5 9 20.5C8.44772 20.5 8 20.9477 8 21.5C8 22.0523 8.44772 22.5 9 22.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M20 22.5C20.5523 22.5 21 22.0523 21 21.5C21 20.9477 20.5523 20.5 20 20.5C19.4477 20.5 19 20.9477 19 21.5C19 22.0523 19.4477 22.5 20 22.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M22 3.5V8.5M24.5 6H19.5" stroke="white" stroke-linecap="round"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 1.5C0.5 1.22386 0.723858 1 1 1H5C5.23831 1 5.44351 1.16819 5.49028 1.40187L6.31051 5.5H16.5C16.7761 5.5 17 5.72386 17 6C17 6.27614 16.7761 6.5 16.5 6.5H6.51066L8.17028 14.7919L8.17042 14.7926C8.239 15.1379 8.42685 15.4481 8.70108 15.6688C8.97531 15.8896 9.31844 16.0068 9.67042 16.0001L9.68 15.9999V16H19.4H19.4096L19.4096 16.0001C19.7616 16.0068 20.1047 15.8896 20.3789 15.6688C20.6519 15.4491 20.8393 15.1407 20.9086 14.7974L21.5077 11.4129C21.5558 11.1409 21.8152 10.9595 22.0871 11.0077C22.3591 11.0558 22.5405 11.3152 22.4923 11.5871L21.8923 14.9771L21.8905 14.9874L21.8904 14.9874C21.7761 15.5629 21.463 16.0799 21.006 16.4478C20.5503 16.8146 19.9803 17.01 19.3955 17H9.68454C9.09968 17.01 8.52975 16.8146 8.07401 16.4478C7.61707 16.0799 7.30403 15.5631 7.18966 14.9878C7.18963 14.9877 7.18961 14.9875 7.18958 14.9874L4.59016 2H1C0.723858 2 0.5 1.77614 0.5 1.5Z" fill="white"/>
                            </svg>
                            </span>
                        </button>
                    }
                    {!available &&
                        <>
                            <div  style={{"lineHeight": "18px"}} className="text-center focus" dangerouslySetInnerHTML={{__html: t("ecommerce.common.product.isOutOfStock")}}/>
                            <div><br /></div>
                        </>
                    }
                </div>
                {/* <div className="product-link__wishlist">
                    <button data-id={sku} onClick={() => addRemoveWishlistLineItem(sku)} className={`${inWishlist && "inWishlist"} btn_addToWishlist`} title="Wishlist">
                        <span className="icon"></span>
                        <span className="testo">{t("ecommerce.common.product.addToWishlist")}</span>
                        <span className="testo2">{t("ecommerce.common.product.addedToWishlist")}</span>
                    </button>
                </div> */}
            </div>
        </StyledFrequentlyBought>
    )
};