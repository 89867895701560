import * as React from "react"
import { parsePrice, createPrice } from "../../common/constants"
import { LabelContext } from "../../core/label.context"
import { GeneralContext } from "../../core/general.context"
import {
    PROJECT_LANGUAGE,
    calls
} from "../../core/APIUtils"
import { RESTContext } from "../../core/rest.context"
import {getBundlePrice, getAttributeFromProductProjection} from "../bundle/utils"


export const CartRecapProduct = (props: any) => {
    const { t } = React.useContext(LabelContext)
    const { cart } = React.useContext(GeneralContext)
    const { ajax$ } = React.useContext(RESTContext)
    const [isBundle, setIsBundle] = React.useState(false)
    const [title, setTitle] = React.useState(props.title)
    const [sku, setSku] = React.useState(props.sku)
    const [bundlePrices, setBundlePrices] = React.useState<any>(null);
    const [productQuantity, setProductQuantity] = React.useState<number>(0)
    const [notDiscountedTotalPrice, setNotDiscountedTotalPrice] = React.useState<any>(null)
    const [totalPrice, setTotalPrice] = React.useState<any>(null)

    // Logica bundle
    React.useEffect(() => {

        if (props.bundleKey) {
            ajax$(calls.Project.getProduct(props.bundleKey)).subscribe(
                bundleProjection => {
                    setIsBundle(true)
                    setTitle(bundleProjection.name[PROJECT_LANGUAGE])
                    let prices = getBundlePrice(bundleProjection)
                    setBundlePrices(prices)

                    let productsInBundle = getAttributeFromProductProjection(bundleProjection, "bundle")
                    let skus = productsInBundle.reduce((skus: string, product: any) => {
                        return skus + product.obj.key + " (" + product.obj.masterData.current.name[PROJECT_LANGUAGE] + ")<br>"
                    }, "")
                    setSku(skus)

                    let _availableQuantity = 0
                    productsInBundle.forEach((product: any) => {
                        if (product.obj.masterData.current.masterVariant.availability.availableQuantity < _availableQuantity || _availableQuantity === 0) {
                            _availableQuantity = product.obj.masterData.current.masterVariant.availability.availableQuantity
                        }
                    })
                })
        }

    }, [props.bundleKey])


    React.useEffect(() => {
        /* I nomi delle variabil rispettano la struttura di CT */

        /*   Prezzo unitario per predotto   */
        let price__value = props.item.price.value

        /*   Prezzo unitario con promozione a prodotto.   Opzionale   */
        let price__discounted = props.item.price.discounted?.value

        /*   Prezzo unitario per promozione a carrello applicata a prezzo scontato.   Opzionale   */
        let discountedPrice = props.item.discountedPrice?.value

        /*   Prezzo totale (non unitario) riga con tutte promozioni applicate*/
        setTotalPrice(props.item.totalPrice)

        let _notDiscountedTotalPrice = undefined

        if (price__discounted || discountedPrice) {
            // Prezzo intero senza promozioni
            _notDiscountedTotalPrice = createPrice(price__value.centAmount * props.item.quantity, cart.totalPrice.currencyCode)
            setNotDiscountedTotalPrice(_notDiscountedTotalPrice)
        }

        setProductQuantity(Number(props.quantity))

        if (!props.bundleKey) {
            setSku(props.sku)
            setTitle(props.title)
        }
    }, [props.item, isBundle, cart])
    
    return (
        <>
            {
                <div className="">
                    <div className="row">
                        <div className="col-8 font-size-14">
                            <div className="product-information">
                                {title}
                                <div dangerouslySetInnerHTML={{__html: sku}}></div>
                            </div>
                            <div className="product-quantity pb-3">{t("ecommerce.checkout.quantity-small")}: {props.quantity}</div>
                        </div>
                        <div className="col-4 text-right">
                            <>
                                {
                                    !isBundle
                                        ?
                                            <>
                                                {notDiscountedTotalPrice && <div className="price price--strikethrough">{parsePrice(notDiscountedTotalPrice)}</div>}
                                                {totalPrice && <div className="price price--discounted">{parsePrice(totalPrice)}</div>}
                                            </>
                                        :
                                            (bundlePrices &&
                                                <>
                                                    <div className="price price--strikethrough">{parsePrice(createPrice(bundlePrices?.productsPrice * productQuantity, cart.totalPrice.currencyCode))}</div>
                                                    <div className="price price--discounted">{parsePrice(createPrice(bundlePrices?.bundlePrice * productQuantity, cart.totalPrice.currencyCode))}</div>
                                                </>
                                            )
                                }
                            </>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}