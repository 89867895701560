
import { StyledProduct } from "./productInfo.style";
import { parsePrice } from "../../common/constants";
import {PROJECT_LANGUAGE, BRX_PRODUCTS_URL, BRX_ASSETS_URL, PROJECT_SETTINGS} from "../../core/APIUtils";
import { LabelContext } from "../../core/label.context";
import React from "react";
import { Property } from "../elements/property/property.component";
import {getCurrentPrice} from "../bundle/utils"


export const ProductInfo = (props: any) => {
  const { item, services, callerComponent } = props;
  const { t } = React.useContext(LabelContext);
  const [currentPrice, setCurrentPrice] = React.useState<any>(null)
  const [productUrl, setProductUrl] = React.useState<string>("")

  React.useEffect(() => {
    let price = getCurrentPrice(item.variant.prices)
    setCurrentPrice(price)
    setProductUrl(BRX_PRODUCTS_URL + item.variant.sku)
  },[])

  let itemServices: string[] = [];
  if (services) {
    services.forEach((s: any, i: any) => {
      if (s.custom?.fields?.relatedLineItem?.id == item.productId) {
        itemServices[i] = s.name.it
      }
    }
    )
  }

  return (
    <StyledProduct>
      <div className="row">
        <div className="col-4 col-lg-4 col-xl-3">
          {item.variant && item.variant.images && item.variant.images.length > 0
              ?
                <>
                  <a href={productUrl}>
                    <img src={item.variant.images[0]?.url} className="productImage" alt="" />
                  </a>
                </>
              :
                <>
                  <img className="img-fluid" alt="placeholder" src={`${BRX_ASSETS_URL}/images/product_placeholder.png`}/>
                </>
          }
        </div>
        <div className="col-8 col-lg-8">
          {item.name[PROJECT_LANGUAGE] && <div className="productTitle">
            <a href={productUrl} className="font-weight-bold text-underline-hover">{item.name[PROJECT_LANGUAGE]}</a>
          </div>}
          {(item.productType.obj?.key !== "services") &&
            <div className="productSlug">
              <a href={productUrl} className="text-underline-hover">
                {item.variant.sku}
              </a>
            </div>
          }

          {callerComponent !== 'wishlist' && <Property label={t("ecommerce.checkout.quantity")} item={item.quantity}/>}

          {currentPrice && currentPrice?.discounted &&
            <>
              <div className="price price--strikethrough"> {parsePrice(currentPrice?.value)}</div>
              {parsePrice(currentPrice?.discounted?.value)}
            </>
          }
          {currentPrice && !currentPrice?.discounted &&
            <>
              {parsePrice(currentPrice?.value)}
            </>
          }

          {item.variant.availability?.availableQuantity <= 0 && PROJECT_SETTINGS.inventoryMode !== "ReserveOnOrder" &&
            <div className="focus-info focus-info-small mt-3" style={{color: 'var(--color-alert-light)'}}>
              {t('ecommerce.checkout.item-will-arrive-late')}
            </div>
          }

          {itemServices.length >= 1 &&
            <div className="services" >
              <span className="text-bold">{t("ecommerce.orders.activeServices")}</span>
              {itemServices.map((s: any, i: any) => <div key={i}>{s}</div>)}
            </div>
          }
        </div>




      </div>
    </StyledProduct>
  );
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function t(arg0: string): import("react").ReactNode {
  throw new Error("Function not implemented.");
}

