import React from "react";
import { BackInStockForm, BackInStockFormProductDetail } from "../form/backInStockForm";
import { ToggleCartLayer } from "../components/cart/cart.layer.component";
import { EasyLogin } from "../components/login/login.component";
import { PaymentMethodsIconsLayer } from "../components/paymentmethods/paymentMethodsIcons.component";
import { RelatedItemsLayer, SliderListFromCategoryCT } from "../components/relateditems/relateditems.layer";
import { WishlistIcon } from "../components/wishlist/wishlist.component";
import { InjectBehaviour } from "../hooks/injectBehavour.hook";
import { TokenDetection } from "../hooks/tokenDetection.hook";
import { ActivatePage } from "../pages/ActivatePage";
import { CartPage } from "../pages/CartPage";
import { CheckoutPage } from "../pages/CheckoutPage";
import { ExamplePage } from "../pages/ExamplePage";
import { ForgotPasswordPage } from "../pages/forgotPasswordPage";
import { LoginPage } from "../pages/LoginPage";
import { MyAccountPage } from "../pages/MyAccountPage";
import { ResetPasswordPage } from "../pages/resetPasswordPage";
import { SignupPage } from "../pages/SignupPage";
import { ThankYouPage } from "../pages/ThankYouPage";
import { KlarnaConfirmationPage } from "../pages/KlarnaConfirmationPage";
import { PayUConfirmationPage } from "../pages/PayUConfirmationPage";
import { YounitedConfirmationPage } from "../pages/YounitedConfirmationPage";
import { WishlistPage } from "../pages/WishlistPage";
import { GeneralContext } from "./general.context";
import { RESTContext } from "./rest.context";
import { CartContext } from "./cart.context"
import { AlternativeProductPopup } from "../components/popup/alternativeProductPopup"
import { ExternalPromoPopup } from "../components/popup/externalPromoPopup"
import { RelatedBundles } from "../components/bundle/relatedbundles";
import { BundleListComponent } from "../components/bundle/bundle.list.component";
import { InjectBundleCategoryComponent } from "../components/bundle/bundle.list.component";
import { Facets } from "../components/facets/facets.component";
import { LabelContext } from "./label.context"
import { BuyBoxConfirmationPage } from "../pages/BuyBoxConfirmationPage";
import { PayFastConfirmationPage } from "../pages/PayFastConfirmationPage"

export const Instances = (props: any) => {
  const { token } = React.useContext(RESTContext);
  const { labels } = React.useContext(LabelContext);
  const { cart, wishlist, orders, openBackInStock } = React.useContext(GeneralContext);
  const { skuForPopup, externalPopup, giftPromoId } = React.useContext(CartContext);

  return <>
    <TokenDetection />
    {labels && labels.length > 0 && <InjectBehaviour />}
    <ExamplePage />
    {token && <LoginPage />}
    {token && <SignupPage />}
    {token && <ForgotPasswordPage />}
    {token && <ResetPasswordPage />}
    {token && cart && wishlist && <MyAccountPage />}
    {token && <EasyLogin />}
    {token && <WishlistIcon />}
    {token && <PaymentMethodsIconsLayer />}
    {cart && wishlist && <CartPage />}
    {cart && <ToggleCartLayer />}
    {cart && <CheckoutPage />}
    {cart && wishlist && <SliderListFromCategoryCT />}
    {cart && wishlist && <RelatedItemsLayer />}
    {cart && wishlist && <RelatedBundles />}
    {wishlist && <WishlistPage />}
    {<ActivatePage />}
    {openBackInStock && <BackInStockForm imageUrl={openBackInStock.imageUrl} sku={openBackInStock.sku} name={openBackInStock.name} title={openBackInStock.title} description={openBackInStock.description} />}
    {cart && <BackInStockFormProductDetail/>}
    {orders && <ThankYouPage />}
    {cart && <KlarnaConfirmationPage />}
    {cart && <PayUConfirmationPage />}
    {cart && <PayFastConfirmationPage />}
    {cart && <BuyBoxConfirmationPage/>}
    {cart && <YounitedConfirmationPage />}
    {skuForPopup && <AlternativeProductPopup sku={skuForPopup} />}
    {externalPopup && giftPromoId && <ExternalPromoPopup cart={cart} promoId={giftPromoId} />}
    {<BundleListComponent />}
    {token && <Facets />}
    {cart && <InjectBundleCategoryComponent />}
  </>;
}