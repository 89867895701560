import * as React from "react";
import {
    PROJECT_LANGUAGE,
    BRX_BUNDLE_URL,
    BRX_PRODUCTS_URL,
    BRX_ASSETS_URL,
    PROJECT_SETTINGS,
    PROJECT_COUNTRY,
    PROJECT_KEY,
    PRODUCT_WITH_LIMITED_QTA_ENABLED,
    SHOW_ENERGY_LABEL, BACK_IN_STOCK_LISTING_ENABLED
} from "../../core/APIUtils";
import Slider from "react-slick";
import { LabelContext } from "../../core/label.context";
import { SymbolContext } from "../../core/symbol.context";
import { GeneralContext } from "../../core/general.context";
import { nordics, openCartLayer, parsePrice } from "../../common/constants";
import { CartContext } from "../../core/cart.context";
import {
    getBundlePrice,
    getCurrentPrice, getEnergyClassLabel, removeBundleCancelledFromProductProjection,
} from "../bundle/utils"

export const BundleItem = (props: any) => {
    const { item, i, inCart = false, InjectBundle = false, minQta } = props;
    const [price] = React.useState<string>(props.productPrice);
    const [discountPercentValue] = React.useState(props.discountPercentValue);
    const [available] = React.useState<boolean>(props.available);
    const { t } = React.useContext(LabelContext);
    const { addLineItem } = React.useContext(CartContext);
    const { wishlist } = React.useContext(GeneralContext);
    const [itemWillArriveLater, setItemWillArriveLater] = React.useState(false)
    const [showLabelItemInStockWithLimitedQta, setShowLabelItemInStockWithLimitedQta] = React.useState(false)
    const [showLabelItemInStock, setShowLabelItemInStock] = React.useState(false)


    let currentItem: any;
    let name: string;
    let description: string;
    if (item.obj) {
        currentItem = item.obj?.masterData?.current?.masterVariant
        name = item.obj?.masterData?.current?.name;
        description = item.obj?.masterData?.current?.description;
    } else {
        currentItem = item.masterVariant
        name = item.name
        description = item.description
        let descriptionAttr = currentItem.attributes.find((attribute: any) => {
            if(attribute.name === 'description-for-cart'){
                return true
            }
        })
        if(descriptionAttr){
            description = descriptionAttr['value']
        }
    }
    

    const { sku, images, prices } = currentItem;


    let inWishlist = false;
    if (wishlist && wishlist.lineItems.length > 0) {
        inWishlist = wishlist.lineItems.some(item => item.variant && item.variant.sku === sku);
    }


    let backgroundColor = "#FFF";
    let backgroundColorAttribute = currentItem.attributes.find((item:any) => item.name === "background-color" )
    if(backgroundColorAttribute){
        backgroundColor = backgroundColorAttribute.value;
    }


    // Distinzione tra stile a carrello in in pagina
    let bundleClass = "cartProduct"
    let bundleStyle = {"padding":"5px", "margin":"0 auto", border: '1px solid #b4b4b4'};
    if(inCart){
        bundleClass = "cartProduct";
    }

    React.useEffect(() => {
        if (PROJECT_SETTINGS.inventoryMode === "TrackOnly" || available){
            if(minQta <= 0){
                setItemWillArriveLater(true)
            } else {
                if (PRODUCT_WITH_LIMITED_QTA_ENABLED != null) {
                    const attrs = PRODUCT_WITH_LIMITED_QTA_ENABLED.split("|");
                    if (attrs.length === 2) {
                        const [enabled, qtaThresholdString] = attrs;
                        const qtaThreshold = Number(qtaThresholdString);
                        if (enabled === "true" && !isNaN(qtaThreshold)) {
                            if(minQta && minQta >= 1 && minQta <= qtaThreshold){
                                setShowLabelItemInStockWithLimitedQta(true)
                            }else if((["PL"].includes(PROJECT_COUNTRY) && minQta > qtaThreshold)) {
                                setShowLabelItemInStock(true)
                            }
                        }
                    }
                }
            }
        }
    },[])


    return (
        <>
            {!InjectBundle ?
                <div className={bundleClass} style={bundleStyle}>
                    <div key={i} style={{"backgroundColor":backgroundColor}} className="justify-content-around row align-items-center  no-gutters" >
                        <div className="col-lg-5 ">
                            <a href={BRX_BUNDLE_URL + sku}>
                            <>
                            {images && images.length > 0 && images[0].url
                                ?
                                    <>
                                        <div className="product-preview__gallery p-2">
                                            <img className="img-fluid" src={images[0]?.url} alt="" />
                                        </div>
                                    </>
                                :
                                    <>
                                        <img className="img-fluid" alt="placeholder" src={`${BRX_ASSETS_URL}/images/product_placeholder.png`}/>
                                    </>
                            }
                            </>
                            </a>
                        </div>

                        <div className="col-lg-5 text-center text-lg-left">
                            <div className="name" style={{"textTransform": "uppercase",  "fontWeight":"bold", "lineHeight": "24px"}} >{name[PROJECT_LANGUAGE]}</div>
                            <div className="product-preview__description mt-2" style={{"lineHeight": "20px"}} dangerouslySetInnerHTML={{ __html: description[PROJECT_LANGUAGE] }}></div>
                            <div className="promotion-preview">
                                <div className="promotion-preview__header"> 
                                    <div className="prices text-right text-lg-left">
                                        { discountPercentValue >0 && 
                                            <div className="price" style={{"textDecoration":"line-through", "fontSize":"0.9rem", "color":"#b4b4b4"}}>{price}</div>
                                        }
                                        <div className="price price--discounted">{parsePrice(getCurrentPrice(prices).value)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="product-links">
                                <div className="product-link__buy">
                                    {available &&
                                        <button data-id={sku} onClick={() => { addLineItem(sku); openCartLayer(); }} className="btn_addToCart button-primary" title={t("ecommerce.common.product.addToCart")}>
                                            <span className="btn-text">{t("ecommerce.common.product.addToCartShort")}</span>
                                            <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                                                <path d="M9 22.5C9.55228 22.5 10 22.0523 10 21.5C10 20.9477 9.55228 20.5 9 20.5C8.44772 20.5 8 20.9477 8 21.5C8 22.0523 8.44772 22.5 9 22.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M20 22.5C20.5523 22.5 21 22.0523 21 21.5C21 20.9477 20.5523 20.5 20 20.5C19.4477 20.5 19 20.9477 19 21.5C19 22.0523 19.4477 22.5 20 22.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M22 3.5V8.5M24.5 6H19.5" stroke="white" stroke-linecap="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 1.5C0.5 1.22386 0.723858 1 1 1H5C5.23831 1 5.44351 1.16819 5.49028 1.40187L6.31051 5.5H16.5C16.7761 5.5 17 5.72386 17 6C17 6.27614 16.7761 6.5 16.5 6.5H6.51066L8.17028 14.7919L8.17042 14.7926C8.239 15.1379 8.42685 15.4481 8.70108 15.6688C8.97531 15.8896 9.31844 16.0068 9.67042 16.0001L9.68 15.9999V16H19.4H19.4096L19.4096 16.0001C19.7616 16.0068 20.1047 15.8896 20.3789 15.6688C20.6519 15.4491 20.8393 15.1407 20.9086 14.7974L21.5077 11.4129C21.5558 11.1409 21.8152 10.9595 22.0871 11.0077C22.3591 11.0558 22.5405 11.3152 22.4923 11.5871L21.8923 14.9771L21.8905 14.9874L21.8904 14.9874C21.7761 15.5629 21.463 16.0799 21.006 16.4478C20.5503 16.8146 19.9803 17.01 19.3955 17H9.68454C9.09968 17.01 8.52975 16.8146 8.07401 16.4478C7.61707 16.0799 7.30403 15.5631 7.18966 14.9878C7.18963 14.9877 7.18961 14.9875 7.18958 14.9874L4.59016 2H1C0.723858 2 0.5 1.77614 0.5 1.5Z" fill="white"/>
                                            </svg>
                                            </span>
                                        </button>
                                    }
                                    {!available &&
                                        <>
                                            <div  style={{"lineHeight": "18px"}} className="text-center focus" dangerouslySetInnerHTML={{ __html: t("ecommerce.common.product.isOutOfStock") }}></div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
                :
                <>
                    <div className="product-content">
                        <a style={{"textDecoration": "none"}} href={BRX_BUNDLE_URL + sku}>
                            <div className="product-preview__gallery mb-2">
                                {item.customDiscountLabel ?
                                    <>
                                        <span className="float-right" style={{"backgroundColor":"#640000", "color":"#FFFFFF", "padding":"0px 20px", "fontWeight":"bold"}} >{item.customDiscountLabel}</span>
                                    </>
                                :
                                    <>
                                    {discountPercentValue > 0 &&
                                        <span className="float-right" style={{"backgroundColor":"#640000", "color":"#FFFFFF", "padding":"0px 20px", "textTransform":"uppercase", "fontWeight":"bold"}} >-{discountPercentValue}%</span>
                                    }
                                    {discountPercentValue <= 0 &&
                                        <span className="float-right">&nbsp;</span>
                                    }
                                    </>
                                }
                            <>
                                {images && images.length > 0 && images[0].url
                                    ?
                                    <>
                                        <img className="img-fluid" src={images[0].url} alt="" />
                                    </>
                                    :
                                    <>
                                        <img className="img-fluid" alt="placeholder" src={`${BRX_ASSETS_URL}/images/product_placeholder.png`}/>
                                    </>
                                }
                            </>
                            </div>
                            <div className="row product-preview__header mb-2">
                                <div className="col-md-6 name">{name[PROJECT_LANGUAGE]}</div>
                                <div className="col-md-6 promotion-preview">
                                    <div className="promotion-preview__header">

                                        <div className="prices">
                                            <div className="price price--strikethrough">
                                                {discountPercentValue > 0 && price &&
                                                    <>{price}</>
                                                }
                                            </div>
                                            {prices.length > 0 &&
                                                <div className="price price--discounted">{parsePrice(getCurrentPrice(prices).value)}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="product-preview__description">{description[PROJECT_LANGUAGE]}</div>*/}
                        </a>
                    </div>

                    <div className="product-links">
                        <div className="product-link__buy">
                            {available &&
                                <button data-id={sku} onClick={() => { addLineItem(sku); openCartLayer(); }} className="btn_addToCart button-primary" title={t("ecommerce.common.product.addToCart")}>
                                    {t("ecommerce.common.product.addToCart")}
                                </button>
                            }
                            {!available &&
                                <>
                                    <div className="text-center focus" style={{"lineHeight": "1.2rem"}} dangerouslySetInnerHTML={{__html: t("ecommerce.common.product.isOutOfStock")}}/>
                                    <div><br /></div>
                                </>
                            }
                        </div>
                    </div>

                    {itemWillArriveLater &&
                        <div className="focus-info focus-info-small mt-3 text-center" style={{color: 'var(--color-alert-light)'}}>{t('ecommerce.checkout.listing.item-will-arrive-late')}</div>
                    }
                    {showLabelItemInStockWithLimitedQta &&
                        <div className="focus-info focus-info-small mt-3 text-center" style={{ color: '#faa914' }}>{t('ecommerce.checkout.item-in-stock-limited-qta').replace("{qta}",minQta.toString())}</div>
                    }
                    {showLabelItemInStock &&
                        <div className="focus-info focus-info-small mt-3 text-center" style={{ color: 'var(--color-success)' }}>{t('ecommerce.checkout.item-in-stock')}</div>
                    }

                    <div className="free-field free-field--2 product-free-field product-preview__free-field">
                    </div>
                </>
            }
        </>
    )
};

export const RelatedItem = (props: any) => {
    const { item, i } = props;
    const { t } = React.useContext(LabelContext);
    const { getSymbolBySku } = React.useContext(SymbolContext);
    const { addLineItem, addRemoveWishlistLineItem } = React.useContext(CartContext);
    const { wishlist, setOpenBackInStock, openBackInStock } = React.useContext(GeneralContext);
    const [bundleInfo, setBundleInfo] = React.useState<any>({})
    const [isAvailable, setIsAvailable] = React.useState(false)
    const [itemWillArriveLater, setItemWillArriveLater] = React.useState(false)
    const [showLabelItemInStockWithLimitedQta, setShowLabelItemInStockWithLimitedQta] = React.useState(false)
    const [showLabelItemInStock, setShowLabelItemInStock] = React.useState(false)
    const [availableQta, setAvailableQta] = React.useState(0)
    const [showBackInStock, setShowBackInStock] = React.useState(false)

    let currentItem: any;
    let name: string;
    let backInStockActive = Boolean(BACK_IN_STOCK_LISTING_ENABLED === 'true')

    React.useEffect(() => {
        if(openBackInStock){
            window.openBIS()
        }
    },[openBackInStock])

    React.useEffect(() => {
        if (item && item.productType && item.productType.obj && item.productType.obj.key === 'BUNDLE'){
            let bundleInfo = getBundlePrice(item)
            let bundleDescription = item.obj ? item.obj?.masterData?.current?.description : item.description

            let descriptionAttr = item.masterVariant.attributes.find((attribute: any) => {
                if(attribute.name === 'description-for-cart'){
                    return true
                }
            })

            if(descriptionAttr){
                bundleDescription = descriptionAttr['value']
            }
            bundleInfo.description = bundleDescription[PROJECT_LANGUAGE]
            setBundleInfo(bundleInfo)
            setIsAvailable(bundleInfo.isAvailable)
            setShowBackInStock(!isAvailable && backInStockActive)

            if (PROJECT_SETTINGS.inventoryMode === "TrackOnly" || isAvailable){
                if(bundleInfo.minQta <= 0){
                    setItemWillArriveLater(true)
                } else {
                    if (PRODUCT_WITH_LIMITED_QTA_ENABLED != null) {
                        const attrs = PRODUCT_WITH_LIMITED_QTA_ENABLED.split("|");
                        if (attrs.length === 2) {
                            const [enabled, qtaThresholdString] = attrs;
                            const qtaThreshold = Number(qtaThresholdString);
                            if (enabled === "true" && !isNaN(qtaThreshold)) {
                                if(bundleInfo.minQta && bundleInfo.minQta >= 1 && bundleInfo.minQta <= qtaThreshold){
                                    setShowLabelItemInStockWithLimitedQta(true)
                                }else if((["PL"].includes(PROJECT_COUNTRY) && bundleInfo.minQta > qtaThreshold)) {
                                    setShowLabelItemInStock(true)
                                }
                            }
                        }
                    }
                }
            }

        } else {
            let isAvailable = item.obj?.masterData?.current?.masterVariant?.availability?.availableQuantity > 0 || item.masterVariant?.availability?.availableQuantity > 0 || (PROJECT_SETTINGS.inventoryMode === "TrackOnly" && (item.masterVariant?.availability || item.obj?.masterData?.current?.masterVariant?.availability))
            setIsAvailable(isAvailable)
            setShowBackInStock(!isAvailable && backInStockActive)
            let availableQta = "obj" in item ? item.obj?.masterData?.current?.masterVariant?.availability?.availableQuantity : item.masterVariant?.availability?.availableQuantity
            setAvailableQta(availableQta)
            if (PROJECT_SETTINGS.inventoryMode === "TrackOnly" || (availableQta > 0)){
                if(availableQta <= 0){
                    setItemWillArriveLater(true)
                }else if(PRODUCT_WITH_LIMITED_QTA_ENABLED != null){
                    const attrs = PRODUCT_WITH_LIMITED_QTA_ENABLED.split("|");
                    if (attrs.length === 2) {
                        const [enabled, qtaThresholdString] = attrs;
                        const qtaThreshold = Number(qtaThresholdString);
                        if (enabled === "true" && !isNaN(qtaThreshold)) {
                            if(availableQta >= 1 && availableQta <= qtaThreshold){
                                setShowLabelItemInStockWithLimitedQta(true);
                            }else if((["PL"].includes(PROJECT_COUNTRY) && availableQta > qtaThreshold) || nordics.includes(PROJECT_KEY)) {
                                setShowLabelItemInStock(true)
                            }
                        }
                    }
                } else if((["PL"].includes(PROJECT_COUNTRY) && availableQta > 2) || nordics.includes(PROJECT_KEY)){
                    setShowLabelItemInStock(true)
                }
            }
        }
    },[])

    if (item.obj) {
        //quando arrivi da RelatedItemLayer (solo prodotti)
        currentItem = item.obj?.masterData?.current?.masterVariant
        name = item.obj?.masterData?.current?.name;
    } else {
        //quando arrivi da SliderFromCategoryCT (prodotti + bundle)
        currentItem = item.masterVariant
        name = item.name
    }
    const { sku, images, prices } = currentItem;

    const energyLabel = getEnergyClassLabel(currentItem, t)
    const productSheetUrl = currentItem.attributes.find((attr: any) => attr.name === 'product-sheet-url' && attr.value)

    const { discounted } = getCurrentPrice(prices);

    let inWishlist = false;
    if (wishlist && wishlist.lineItems.length > 0) {
        inWishlist = wishlist.lineItems.some(item => item.variant && item.variant.sku === sku);
    }

    let productBaseUrl = item && item.productType && item.productType.obj && item.productType.obj.key === 'BUNDLE' ? BRX_BUNDLE_URL : BRX_PRODUCTS_URL

    const isGiftCard = item && item.productType && item.productType.obj && item.productType.obj.key === 'GIFTCARD';

    let symbols = getSymbolBySku(sku)

    const ProductContent = () => (
        <>
            <div className="product-preview__gallery">
                {images.length > 0 &&
                    <img src={images[0].url} alt="" />
                }
            </div>
            <div className="product-preview__header">
                {(item && item.productType && item.productType.obj ? item.productType.obj.key !== 'BUNDLE' : true) &&
                    <div className="name">{sku}</div>
                }
                <div className="promotion-preview">
                    <div className="promotion-preview__header">

                        <div className="prices">
                            <>
                                {item && item.productType && item.productType.obj && item.productType.obj.key === 'BUNDLE'
                                    ?
                                    <>
                                        {bundleInfo.discountPercentValue > 0 &&
                                            <div className="price price--strikethrough">{bundleInfo.productsPriceFormatted}</div>
                                        }
                                        <div className="price price--discounted">{parsePrice(getCurrentPrice(prices).value)}</div>
                                    </>
                                    :
                                    <>
                                        {getCurrentPrice(prices).value.centAmount > 0 && (
                                            discounted ? (
                                                <>
                                                    <div className="price price--strikethrough">{parsePrice(getCurrentPrice(prices).value)}</div>
                                                    <div className="price price--discounted">{parsePrice(discounted.value)}</div>
                                                </>
                                            ) : (
                                                <div className="price">{parsePrice(getCurrentPrice(prices).value)}</div>
                                            )
                                        )}
                                    </>
                                }
                            </>
                        </div>
                    </div>
                </div>
            </div>
            {item && item.productType && item.productType.obj && item.productType.obj.key === 'BUNDLE' ?
                <div className="product-preview__description">{bundleInfo.description}</div>
                :
                <div className="product-preview__description">{name[PROJECT_LANGUAGE]}</div>
            }
        </>
    );

    const ProductContentClickable = () => {
        return(
            <>

                <div className={`mx-0 energy_label`}>
                    {SHOW_ENERGY_LABEL && (energyLabel || (productSheetUrl && productSheetUrl !== "")) &&
                        <>

                            {energyLabel &&
                                <a target="_blank" rel="noreferrer" href={`https://pi-exchange.smeg.it/energy/${sku}/${PROJECT_LANGUAGE}-${PROJECT_COUNTRY}`}>
                                    <img src={energyLabel.src} alt={energyLabel.alt} />
                                </a>
                            }
                            {productSheetUrl && productSheetUrl !== "" &&
                                <a className={`font-weight-light ${energyLabel ? 'pl-3' : ''}`} href={productSheetUrl.value?.replaceAll(" en-GB", " en-IE")} target="_blank" rel="noreferrer">{t("product.productSheetLabel")}</a>
                            }

                        </>
                    }
                </div>

                <div className="energy_label">
                    {symbols && symbols.length > 0 &&
                        <>
                            {symbols.map((symbol: any, index: number) => {
                                return (
                                    symbol.imageUrl && symbol.type !== "label" && (
                                        <div key={symbol.id}>
                                            <div className={`float-right ${index%2 ===0 && symbols.length>1 ? "pl-2": ""}`}>
                                                <img
                                                    className="icon-30"
                                                    onClick={() => window.symbolModal.showModal(symbol)}
                                                    src={symbol.imageUrl}
                                                    alt={symbol.imageAlt}
                                                    title={symbol.imageTitle}
                                                />
                                            </div>
                                        </div>
                                    )
                                );
                            })}
                        </>
                    }
                </div>

            </>
        )
    }

    return (
        <div key={i} className="product-preview listItem" style={{ width: '100%' }}>
            <div className="product-content">
                {isGiftCard ? (
                    <>
                        <ProductContent />
                        <ProductContentClickable />
                    </>
                ) : (
                    <>
                        <a href={productBaseUrl + sku}>
                            <ProductContent />
                        </a>
                        <ProductContentClickable />
                    </>
                )}
                
            </div>

            <div className="product-links">
                <div className="product-link__buy text-center">
                    {isAvailable &&
                        <button data-id={sku} onClick={() => {addLineItem(sku);openCartLayer();}} className="btn_addToCart button-primary" title={t("ecommerce.common.product.addToCart")}>
                            {t("ecommerce.common.product.addToCart")}
                        </button>
                    }
                    {!isAvailable &&
                    <>
                        <div style={{"lineHeight": "18px", "padding": "0.375rem 0"}} className="focus" dangerouslySetInnerHTML={{__html: t("ecommerce.common.product.isOutOfStock")}}/>
                        {showBackInStock && (
                            <button onClick={() => {
                                setOpenBackInStock({
                                    imageUrl: images[0].url,
                                    sku: sku,
                                    name: name,
                                    title: t('ecommerce.common.product.backInStockTitle'),
                                    description: t('ecommerce.common.product.backInStockDescription')
                                })
                            }} className="btn_secondary btn_backInStock">
                                <span className="icon"><i className="far fa-bell"/></span>
                                <span className="testo">{t('ecommerce.common.product.notifyMeWhenBackInStock')}</span>
                            </button>)
                        }
                    </>
                    }
                    {itemWillArriveLater &&
                        <div className="focus-info focus-info-small mt-3" style={{color: 'var(--color-alert-light)'}}>{t('ecommerce.checkout.listing.item-will-arrive-late')}</div>
                    }
                    {showLabelItemInStockWithLimitedQta &&
                        <div className="focus-info focus-info-small mt-3" style={{ color: '#faa914' }}>{t('ecommerce.checkout.item-in-stock-limited-qta').replace("{qta}",availableQta.toString())}</div>
                    }
                    {showLabelItemInStock &&
                        <div className="focus-info focus-info-small mt-3" style={{ color: 'var(--color-success)' }}>{t('ecommerce.checkout.item-in-stock')}</div>
                    }
                </div>
                <div className="product-link__wishlist">
                    <button data-id={sku} onClick={() => addRemoveWishlistLineItem(sku)} className={`${inWishlist && "inWishlist"} btn_addToWishlist`} title="Wishlist">
                        <span className="icon"/>
                        <span className="testo">{t("ecommerce.common.product.addToWishlist")}</span>
                        <span className="testo2">{t("ecommerce.common.product.addedToWishlist")}</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

export const SliderItems = (props: any) => {
    const { items, title, type, numberOfSlidesToShow } = props;
    const alignTitle = props.alignTitle || "left"
    const titleClass = `title text-black mt-4 ${alignTitle === 'center' ? 'text-center' : ''}`;
    const { t } = React.useContext(LabelContext);
    const [sliderSettings, setSliderSettings] = React.useState<any>();

    React.useEffect(() => {
        let _numSlidesToShow = numberOfSlidesToShow && !isNaN(+numberOfSlidesToShow) && +numberOfSlidesToShow > 0
            ? Math.min(+numberOfSlidesToShow, items.length)
            : 5;

        let settings = {
            arrows: true,
            infinite: true,
            slidesToShow: _numSlidesToShow,
            slidesToScroll: 2,
            dots: false,
            mobileFirst: true,
            centerMode: false,

            responsive: [
                {
                    breakpoint: 1919,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        centerMode: false,
                        arrows: true,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        centerMode: false,
                        arrows: true,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                        arrows: true,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                        arrows: true,
                    }
                }
            ]

        };

        let settings_bundle = {
            arrows: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            mobileFirst: false,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 1366,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                        arrows: true,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                        arrows: true,
                    }
                }
            ]
        };

        if (items.length > 0) {
            if (type === "bundle") {
                settings_bundle['infinite'] = items.length > 2;
                setSliderSettings(settings_bundle);
            } else {
                settings['infinite'] = items.length > 5;
                setSliderSettings(settings);
            }
        }



    }, [items])

    return (<>
        {items.length > 0 && sliderSettings && (
            <section className="ecommerce_relatedItems white-section p-0">

                <h2 className={titleClass}>{title ? title : t("ecommerce.common.related.title")}</h2>

                    <div className="products-list">
                        <Slider {...sliderSettings}>
                            {items.map((item: any, i: any) => {
                                let isItemValid  = true
                                if(item && item.productType && item.productType.obj && item.productType.obj.key === 'BUNDLE'){
                                    let bundleAfter = removeBundleCancelledFromProductProjection([item])
                                    if(!bundleAfter || bundleAfter.length === 0){
                                        isItemValid  = false
                                    }
                                }
                                if (type === "bundle") {
                                    return <BundleItem key={i} inCart={false} item={item} available={item.available} productPrice={item.price} discountPercentValue={item.discountPercentValue} i={i} />;
                                } else if (isItemValid) {
                                    return <RelatedItem key={i} item={item} />;
                                }
                                return null
                            })}
                        </Slider>
                    </div>
                </section>
            )}
        </>
    );
}
