import * as React from "react";
import {GeneralContext} from "../core/general.context";
import {LabelContext} from "../core/label.context";
import {parsePrice} from "../common/constants";
import {CartContext} from "../core/cart.context";
import {RESTContext} from "../core/rest.context";
import {
    calls,
    COOKIES,
    PROJECT_COUNTRY,
    PROJECT_LANGUAGE,
    PAYMENT_METHODS,
    PROJECT_SETTINGS
} from "../core/APIUtils";
import {getCurrentPrice} from "../components/bundle/utils"
import { YounitedPay } from "../components/younitedPay/younitedPay.component";
import ReactDOM from "react-dom";

export const InjectBehaviour = () => {
    const {cart, wishlist, setOpenBackInStock} = React.useContext(GeneralContext);
    const {ajax$} = React.useContext(RESTContext);
    const {addLineItem, addRemoveWishlistLineItem} = React.useContext(CartContext);
    const addToCartRef = React.useRef<any>(null);
    const openBackInStockListingRef = React.useRef<any>(null);
    const addWishlistRef = React.useRef<any>(null);
    const productDetailLink = React.useRef<any>(null);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const awc = params.get('awc');
    const itemsUnavailable = React.useRef<any>([]);
    const {t} = React.useContext(LabelContext);
    const younitedDiv = document.getElementById('younited');
    const productPrice = younitedDiv ?  (younitedDiv.getAttribute('data-discounted-price') ? younitedDiv.getAttribute('data-discounted-price'): younitedDiv.getAttribute('data-price')) : null;

    React.useEffect(() => {

        // integration https://wiki.awin.com/ marketing
        ["DE"].includes(PROJECT_COUNTRY) && awc && COOKIES.set("awc", awc)

        window.initAddToCart = () => {
            document.querySelectorAll('.js_addToCart').forEach((productLink) => {
                productLink.removeEventListener('click', addToCartRef.current);
                productLink.addEventListener('click', addToCartRef.current);
                productLink.classList.remove("hidden");
                if (productLink.parentElement?.classList.contains('isOutOfStock')) {
                    productLink.parentElement?.classList.add('product-link_buy', 'btn')
                    productLink.parentElement?.classList.remove('mx-auto')
                    productLink.parentNode?.removeChild(productLink.parentNode?.children[1])
                }
                if (itemsUnavailable.current && itemsUnavailable.current.length > 0 && itemsUnavailable.current.includes(productLink.getAttribute('data-id'))) {
                    productLink.classList.add("hidden");
                    productLink.parentElement?.classList.remove('product-link_buy', 'btn')
                    productLink.parentElement?.classList.add('isOutOfStock')
                    const node = document.createElement('div')
                    node.classList.add('mx-auto')
                    node.innerHTML = t("ecommerce.cart.maxQuantityReached")
                    productLink.parentElement?.appendChild(node)
                }
            });
        }
        if(window.connectif?.managed?.isInitialized()){
            window.connectif.managed.sendEvents([{ type: 'page-visit' }]);
        } else{
            document.addEventListener('connectif.managed.initialized', function onConnectifInitialized() {
                window.connectif.managed.sendEvents([{ type: 'page-visit' }]);
            });
        }

        window.externalAddToCart = (id) => {
            addLineItem(id, false)
        }

    }, []);

    React.useEffect(() => {
        if (cart) {
            openBackInStockListingRef.current = (event: any) => {
                if (event) {
                    let imageUrl = event.target.getAttribute('data-image')
                    let sku = event.target.getAttribute('data-sku')
                    let name = event.target.getAttribute('data-name')
                    let title = event.target.getAttribute('data-title')
                    let description = event.target.getAttribute('data-description')
                    setOpenBackInStock({
                        imageUrl: imageUrl,
                        sku: sku,
                        name: name,
                        title: title,
                        description: description
                    })
                    window.openBIS()
                }
            }

            let itemsInCart: string[] = []
            if (cart.lineItems && cart.lineItems.length > 0) {
                cart.lineItems.forEach((lineItem) => {
                    if (lineItem.quantity >= lineItem.variant.availability.availableQuantity && PROJECT_SETTINGS.inventoryMode === "ReserveOnOrder") {
                        itemsInCart.push(lineItem.variant.sku)
                    }
                })
            }
            itemsUnavailable.current = [...itemsInCart]

            productDetailLink.current = (event: any) => {
                if (event) {
                    //event.preventDefault();
                    let sku = event?.currentTarget?.dataset?.productId;


                    ajax$(calls.Project.getProduct(sku || ""))
                        .subscribe((res: any) => {

                            let family = res?.masterVariant?.attributes?.find((attr: any) => attr.name === "family")?.value?.key
                            let price = getCurrentPrice(res?.masterVariant?.prices)?.value
                            let quantity = 1
                            let aesthetics = res?.masterVariant?.attributes?.find((attr: any) => attr.name === "NEW_AESTHETICS")?.value?.label

                            dataLayer.push({
                                event: 'productClick',
                                ecommerce: {
                                    click: {
                                        currencyCode: `${price.currencyCode}`,
                                        actionField: {
                                            'list': 'sitemapName'
                                        },
                                        products: [{
                                            name: `${res?.name?.[PROJECT_LANGUAGE]}`,
                                            id: `${res?.key}`,
                                            price: `${parsePrice(price, true)}`,
                                            brand: `${aesthetics}`,
                                            category: `${family}`,
                                            variant: `${res?.key}`,
                                            quantity: `${quantity}`
                                        }]
                                    }
                                }
                            });
                        })

                }
            }

            document.querySelectorAll('.js_product-link').forEach((productLink) => {
                productLink.removeEventListener('click', productDetailLink.current);
                productLink.addEventListener('click', productDetailLink.current);
            });

            document.querySelectorAll('.js_backInStock').forEach((productLink) => {
                productLink.removeEventListener('click', openBackInStockListingRef.current);
                productLink.addEventListener('click', openBackInStockListingRef.current);
            });


            addToCartRef.current = (event: any) => {
                if (event) {
                    //event.preventDefault();
                    addLineItem(event.target?.getAttribute('data-id'), false)

                    ajax$(calls.Project.getProduct(event.target.dataset.id || ""))
                        .subscribe((res: any) => {
                            let family = res.masterVariant.attributes.find((attr: any) => attr.name === "family").value.key
                            let price = getCurrentPrice(res.masterVariant.prices).value
                            let quantity = 1
                            let aesthetics = res.masterVariant.attributes.find((attr: any) => attr.name === "NEW_AESTHETICS")?.value.label
                        })
                }
            }

            window.initAddToCart()

            


        }

        return () => {
            document.querySelectorAll('.js_addToCart').forEach((productLink) => {
                productLink.removeEventListener('click', addToCartRef.current);
            });
            document.querySelectorAll('.js_product-link').forEach((productLink) => {
                productLink.removeEventListener('click', productDetailLink.current);
            });
            document.querySelectorAll('.js_backInStock').forEach((productLink) => {
                productLink.removeEventListener('click', openBackInStockListingRef.current);
            });
        };


    }, [cart]);

    React.useEffect(() => {
        if (wishlist) {
            addWishlistRef.current = (event: any) => {
                if (event) {
                    event.preventDefault();
                    // event.stopPropagation();
                    addRemoveWishlistLineItem(event.target?.getAttribute('data-id'))
                }
            }
            document.querySelectorAll('.js_addToWishlist').forEach((productLink) => {

                productLink.removeEventListener('click', addWishlistRef.current);
                productLink.addEventListener('click', addWishlistRef.current);

                let sku = productLink.getAttribute('data-id')

                productLink.classList.remove("inWishlist")

                if (wishlist.lineItems.find((item: any) => item.variant && item.variant.sku === sku)) {
                    productLink.classList.add("inWishlist");
                }

            });
        }
        return () => document.querySelectorAll('.js_addToWishlist').forEach((productLink) => {
                productLink.removeEventListener('click', addWishlistRef.current);
            }
        );
    }, [wishlist]);
    
    if (!younitedDiv || !productPrice)
        return null;
    else
        return ReactDOM.createPortal(
            <>
            {cart && PAYMENT_METHODS?.includes("younited") && <YounitedPay
                cartid={cart.id}
                amount={parseFloat(productPrice).toFixed(2)}
                showPayButton={false}
                setInModal={true}
                /> }
            </>
            , younitedDiv
        );
};